.client {
    * {
        border: none;
        outline: none;
      }
      
      body {
        background: #504e4e;
        color: #18dcff;
      }
      h1 {
        width: 40vw;
      }
      .contact-form {
        margin: 2vh auto 0;
        max-width: 50%;
        max-width: 62vw;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        border: 1px solid #18dcff;
        background: #363434;
        padding: 0 30px 10px;
        box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.637);
      }
      .form-content {
        margin-left: 5vw;
        width: 45vw;
      }
      input {
        background: none;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-size: 1.1rem;
        border-bottom: 1px solid#cec950;
        color: #18dcff;
      }
    input,
      .email-content {
        width: 100%;
        margin: 0 auto;
      }
      input {
        padding: 24px 0 12px;
      }
      .email-content {
        position: relative;
      }
      .email-content input {
        width: 100%;
      }
      #not-mail {
        display: none;
        position: absolute;
        top: 4px;
        color: #ff4d4d;
        right: 0;
        transform-origin: 50% 50%;
      }
      @keyframes dongle {
        0% {
          transform: translate(0px, 0px);
        }
        10% {
          transform: translate(-10px, 0px);
        }
        20% {
          transform: translate(10px, 0px);
        }
        30% {
          transform: translate(-10px, 0px);
        }
        40% {
          transform: translate(10px, 0px);
        }
        50% {
          transform: translate(-10px, 0px);
        }
        60% {
          transform: translate(10px, 0px);
        }
        70% {
          transform: translate(-10px, 0px);
        }
        80% {
          transform: translate(10px, 0px);
        }
        90% {
          transform: translate(-10px, 0px);
        }
        100% {
          transform: translate(0px, 0px);
        }
      }
      .no-button {
        font-size: 1.3rem;
        margin-top: 1vh;
        margin-left: 40%;
        color: #747472c7;
        border: none;
        width: 20%;
      }
      .tiers {
        margin-left: 15vw;
      }
      .deuxtiers {
        margin-left: 5vw;
      }
      .button {
        font-size: 1.3rem;
        margin-top: 1vh;
        color: #cec950;
        background: #363434;
        border: none;
        cursor: pointer;
        transition: .2s;
        width: 10vw;
      }
      .button:hover {
        letter-spacing: 2px;
      }
      .error::placeholder {
        color: #ff4d4d;
      }
      .form-message {
        margin-top: 10px;
        padding: 12px;
        opacity: 0;
        transition: 0.2s ease;
        color: black;
        border-radius: 4px;
        box-shadow: 0 0 2px rgba(51, 51, 51, 0.3);
      }
      .pageItem {
        display: flexbox;
        position: relative;
        width: 90vw;
        height: 63vh;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        overflow-x: hidden;
        overflow-y:auto;
      }

      .pageTexte {
        display: flex;
        position: relative;
        margin-top: 6vh;
        margin-left: 5vw;
    }

    .nameLength {
      color: rgb(97, 93, 93);
      width: 22.5vw;
    }

    .nameCLength {
      width: 22.5vw;
    }

    .villeLength {
      width: 22.5vw;
    }

    .type-content {
      width: 5vw;
      padding: 24px 0 12px;
      background: none;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-size: 1.1rem;
      border-bottom: 1px solid#cec950;
      color: white;
  }

    select {
      margin-left: 5vw;
      width: 10vw;
      color: #18dcff;
      background: #363434;
     }

}