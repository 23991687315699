.navigation {
    text-align: center;
  
  .pieddepage {
    position: absolute;
    background:rgb(4, 4, 131);
    margin-top: 82vh;
    margin-left: -5vw;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 6vh;

  }
  .pieddepage2 {
    position: absolute;
    background:rgb(4, 4, 131);
    margin-top: 75vh;
    margin-left: -5vw;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 6vh;

  }
  .espace {
      display: flex;
      position: absolute;
      margin-top: 1.5vh;
      margin-left: 10vw;
  }

.navColor {
    color: #f7f7f7;
  }

  a {
    margin: 10px;
    padding: 10px;
    position: relative;
    color: rgb(63, 81, 148);
    font-size: 0.8rem;
  }

  /* Couleur utilisée pour les liens lorsque la souris passe dessus */
   a:hover {
    color: rgb(137, 152, 205);
   font-size: 0.8rem;
  }

  /* Dimensions Logo */
  .imageLogo {
    margin-top: 0.6vw;
    height: auto;
    margin-bottom: 1vw;
  }

  .imageLogo2 {
    margin-top: 0.6vw;
    margin-left: -5px;
    height: auto;
    margin-bottom: 1vw;
  }

  /* Dimensions des options */
  .textOption {
    margin-top: 1vw;
    height: auto;
    font-size: 0.8rem;
    margin-bottom: 1vw;
  }

}  