.reponse {
    * {
        border: none;
        outline: none;
      }
      
      body {
        background: #504e4e;
        color: #18dcff;
      }
      .contact-form {
        margin: 6vh auto 0;
        max-width: 50%;
        max-width: 50vw;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        border: 1px solid #18dcff;
        background: #363434;
        padding: 0 30px 10px;
        box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.637);
      }
      h2 {
        margin-top: 35px;
        text-align: center;
        color: #4273f8e8;
      }
      .form-content {
        display: grid;
  //      grid-template-rows: repeat(4, 1fr) 105px;
      }
      input,
      textarea {
        background: none;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-size: 1.1rem;
        color: #18dcff;
      }
      input::placeholder, 
      textarea::placeholder {
       color: rgb(190, 188, 188);
      }
      input {
        padding: 24px 0 24px 24px;
      }
      textarea {
        resize: none;
        padding: 24px 0 12vh 24px;
        border-bottom: 1px solid#cec950;
      }
      .no-button {
        font-size: 1.3rem;
        margin-top: 15px;
        color: #747472c7;
        border: none;
        width: 100%;
      }
      .button {
        font-size: 1.3rem;
        margin-top: 15px;
        color: #cec950;
        background: #363434;
        border: none;
        cursor: pointer;
        transition: .2s;
        width: 100%;
      }
      .button:hover {
        letter-spacing: 2px;
      }
      .error::placeholder {
        color: #ff4d4d;
      }
      .form-message {
        margin-top: 10px;
        padding: 12px;
        opacity: 0;
        transition: 0.2s ease;
        color: black;
        border-radius: 4px;
        box-shadow: 0 0 2px rgba(51, 51, 51, 0.3);
      }
      .pageItem {
        display: flexbox;
        position: relative;
        width: 90vw;
        height: 62vh;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        overflow-x: hidden;
        overflow-y:auto;
      }

}