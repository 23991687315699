.connexion {
/* Google Fonts */
//@import url('https://fonts.googleapis.com/css?family=Anton|Bangers|Carter+One&display=swap';
//@import url('https://fonts.googleapis.com/css?family=Roboto|Roboto+Condensed&display=swap';

    $white-color: #ffffff;
    $light-grey-color: #EAE8E8;
    $grey-color: #adaaaae7;
    $light-green-color: #1ecc78;
    $green-color: #0cb765;
    $red-color: #EB1D27;
    $blue-color: #4043f3;
    $dark-red-color: #d31017;
    $dark-grey-color: #363636;
    $black-color: #333030;
    $deep-black-color: #000000;
    
    * {
        font-family: 'Poppins', sans-serif;
    }

      /* SIGNUP - LOGIN - FORGETPASSWORD */
    .signUpLoginBox {
        background-image: url(../../assets/bg-noise-texture.png);
        background-repeat: repeat;
        -webkit-box-shadow: inset 0vh 7.15vw 10.8vh -0.9vw rgba(0, 0, 0, 0.39);
        -moz-box-shadow: inset 0vh 7.15vw 10.8vh -0.9vw rgba(0, 0, 0, 0.39);
        box-shadow: inset 0vh 7.15vw 10.8vh -0.9vw rgba(0, 0, 0, 0.39);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6vh;
        margin-left: 15vw;
        width: 60vw;
        height: 60vh;
        margin-bottom: 10vh;
     }
    
     .signUpLoginBox2 {
        background-image: url(../../assets/bg-noise-texture.png);
        background-repeat: repeat;
        -webkit-box-shadow: inset 0vh 7.15vw 10.8vh -0.9vw rgba(0, 0, 0, 0.39);
        -moz-box-shadow: inset 0vh 7.15vw 10.8vh -0.9vw rgba(0, 0, 0, 0.39);
        box-shadow: inset 0vh 7.15vw 10.8vh -0.9vw rgba(0, 0, 0, 0.39);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6vh;
        margin-left: 8vw;
        width: 80vw;
        height: 60vh;
        margin-bottom: 10vh;
     }
    
    .slContainer {
        flex: 0 1 80%;
        display: flex;
    }
    
    .formBoxLeftSignup {
        min-height: 50vh;
        background-image: url(../../assets/inscription.png);
        background-repeat: no-repeat;
        background-position: center center;
        flex: 0 1 50%;
    }
    
    .formBoxLeftLogin {
        min-height: 50vh;
        background-image: url(../../assets/connexion.png);
        background-repeat: no-repeat;
        background-position: center center;
        flex: 0 1 50%;
    }
    
    .formBoxLeftForget {
        min-height: 50vh;
        background-image: url(../../assets/motdepasseoublie.png);
        background-repeat: no-repeat;
        background-position: center center;
        flex: 0 1 50%;
    }
    
    .formBoxRight {
        flex: 0 1 50%;
    }
    
    .formBoxRight span {
        display: block;
        color: $red-color;
        text-align: center;
        border: 1px solid $red-color;
        padding: 10px;
        margin: 15px 0;
    }
    
    .formContent {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
    
    .formContent h1 {
        margin: 0 0 40px 0;
        padding: 0;
        color: $white-color;
    }
    
    .formContent .inputBox {
        position: relative;
        height: 8vh;
    }
       
    .formContent .inputBox2 {
        position: relative;
        height: 8vh;
    }
       
    .formContent .inputBox input {
        width: 100%;
        padding: 10px 0;
        font-size: 0.8rem;
        color: $blue-color;
        font-weight: bold;
        margin-bottom: 30px;
        border: none;
        background: transparent;
        border-bottom: 1px solid $white-color;
        outline: none;
    }
    
    .formContent .inputBox2 input {
        width: 60vw;
        padding: 10px 0;
        font-size: 0.8rem;
        color: $blue-color;
        font-weight: bold;
        margin-bottom: 30px;
        border: none;
        background: transparent;
        border-bottom: 1px solid $white-color;
        outline: none;
    }
    
    .formContent .inputBox label {
        color: $white-color;
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px 0;
        font-size: 0.8rem;
        pointer-events: none;
        -webkit-transition: 0.2s ease-in;
        -moz-transition: 0.2s ease-in;
        -o-transition: 0.2s ease-in;
        transition: 0.2s ease-in;
    }
    
    .formContent .inputBox2 label {
        color: $white-color;
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px 0;
        font-size: 0.8rem;
        pointer-events: none;
        -webkit-transition: 0.2s ease-in;
        -moz-transition: 0.2s ease-in;
        -o-transition: 0.2s ease-in;
        transition: 0.2s ease-in;
    }
    
    .formContent .inputBox input:focus~label,
    .formContent .inputBox input:valid~label {
        top: -3vh;
        left: 0;
        color: $white-color;
        font-size: 0.8rem;
        font-style: italic;
    }
    
    .formContent .inputBox2 input:focus~label,
    .formContent .inputBox2 input:valid~label {
        top: -3vh;
        left: 0;
        color: $white-color;
        font-size: 0.8rem;
        font-style: italic;
    }
    
    /* Form buttons */
    .formContent button {
        margin-left: 0;
        display: block;
        margin: 0 auto 20px;
        min-width: 7.5vw;
        background-color: transparent;
        color: $white-color;
        padding: 7px;
        font-size: 0.8rem;
        border-radius: 5px;
    }
    
    .formContent button:hover {
        background-color: $blue-color;
        color: $white-color;
        cursor: pointer;
    }
    
    button:disabled,
    button[disabled],
    button:disabled:hover,
    button[disabled]:hover {
        border: 1px solid $light-grey-color;
        background-color: $light-grey-color;
        color: $dark-grey-color;
        opacity: 0.65;
        cursor: not-allowed;
    }
    
    .linkContainer {
        height: 5vh;
        margin-top: 3.5vh;
        padding-top: 1.5vh;
        border-top: 1px dashed $light-grey-color;
    }
    
    .simpleLink {
        color: $white-color;
        text-decoration: none;
        font-size: 0.8rem;
    }
    
    .simpleLink:hover {
        color: $blue-color;
    }
    /* END SIGNUP - LOGIN - FORGETPASSWORD */
    
    /* LOGOUT SWITCH */

    .logoutContainer {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 3vh;
    }
    
    .switch {
        position: relative;
        display: inline-block;
        width: 3vw;
        height: 3.4vh;
    }
    
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $grey-color;
        -webkit-transition: .4s;
        transition: .4s;
    }
    
    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }
    
    input:checked+.slider {
        background-color: $red-color;
    }
    
    input:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    
    .slider.round {
        border-radius: 34px;
    }
    
    .slider.round:before {
        border-radius: 50%;
    }
    /* END LOGOUT SWITCH */
    
    /* LOADER */
    .loader {
        border: 10px solid $light-grey-color;
        border-radius: 50%;
        border-top: 10px solid $dark-red-color;
        width: 2.5vw;
        height: 5vh;
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
        margin: 40px auto 20px;
    }
    
    .loaderText {
        text-align: center;
        color: $white-color;
    }
    
    @-webkit-keyframes spin {
        0% {
        -webkit-transform: rotate(0deg);
        }
    
        100% {
        -webkit-transform: rotate(360deg);
        }
    }
    
    @keyframes spin {
        0% {
        transform: rotate(0deg);
        }
    
        100% {
        transform: rotate(360deg);
        }
    }
    /* END LOADER */
   
}