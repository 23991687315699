@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");

$font-1: "Poppins", sans-serif;

$color-1: #61dafb;
$color-2: #F7F7F7;
$color-3: rgb(63, 81, 148); // bleu turquin
$color-4: rgb(97, 165, 52); // vert sinople
$color-5: rgb(191, 113, 60); // orange
$color-6: rgb(158, 53, 53); // rouge foncé
$color-7: #b4b1b1;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0.4rem auto;
  max-width: 90vw;
  max-height: 90vh;
  font-family: $font-1;
  color: $color-3;
  background-color: $color-2;
  overflow-x: hidden;
}
li {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: $color-3;
  cursor: pointer;
}
a:visited {
  color: $color-3;
}

/* ScrollBar */
/* Couleur de fond */
::-webkit-scrollbar-track {
  box-shadow:  inset 0 0 6px rgba(0, 0, 0, 0.1); 
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #3E5194;
  //border-radius: 10px;
  }
  
  /* Format */
  ::-webkit-scrollbar {
  width: 8px;
  background-color: #FFFFFF;
  }
  
  /* couleur de la barre */
  ::-webkit-scrollbar-thumb {
  //border-radius: 10px;
  box-shadow:  inset 0 0 6px rgba(0, 0, 0, 0.1); 
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #A7B6ED;
  }
  
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #FFFFFF;
    opacity: 1; /* Firefox */
  }
  
