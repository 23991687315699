.contact {
    * {
        border: none;
        outline: none;
      }
      
      body {
        background: #504e4e;
        color: #18dcff;
      }
      .contact-form {
        margin: 4vh auto 0;
        max-width: 50%;
        max-width: 50vw;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        border: 1px solid #18dcff;
        background: #363434;
        padding: 0 30px 10px;
        box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.637);
      }
      .contact-form2 {
        margin: 4vh auto 0;
        max-width: 80%;
        max-width: 80vw;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        border: 1px solid #18dcff;
        background: #363434;
        padding: 0 30px 10px;
        box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.637);
      }
      h2 {
        margin-top: 35px;
        text-align: center;
        color: #4273f8e8;
      }
      .form-content {
        display: grid;
        grid-template-rows: repeat(4, 1fr) 105px;
      }
      input,
      textarea {
        background: none;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-size: 0.8rem;
        border-bottom: 1px solid#cec950;
        color: #18dcff;
      }
      input::placeholder, 
      textarea::placeholder {
       color: rgb(190, 188, 188);
      }
      input,
      .email-content,
      textarea {
        width: 90%;
        margin: 0 auto;
      }
      input {
        padding: 24px 0 12px;
      }
      .email-content {
        position: relative;
      }
      .email-content input {
        width: 100%;
      }
      #not-mail {
        display: none;
        position: absolute;
        top: 4px;
        color: #ff4d4d;
        right: 0;
        transform-origin: 50% 50%;
      }
      @keyframes dongle {
        0% {
          transform: translate(0px, 0px);
        }
        10% {
          transform: translate(-10px, 0px);
        }
        20% {
          transform: translate(10px, 0px);
        }
        30% {
          transform: translate(-10px, 0px);
        }
        40% {
          transform: translate(10px, 0px);
        }
        50% {
          transform: translate(-10px, 0px);
        }
        60% {
          transform: translate(10px, 0px);
        }
        70% {
          transform: translate(-10px, 0px);
        }
        80% {
          transform: translate(10px, 0px);
        }
        90% {
          transform: translate(-10px, 0px);
        }
        100% {
          transform: translate(0px, 0px);
        }
      }
      textarea {
        resize: none;
        padding: 24px 0;
      }
      .no-button {
        font-size: 1.3rem;
//        margin-top: 0px;
        color: #747472c7;
        border: none;
        width: 100%;
      }
      .button {
        font-size: 1.3rem;
//        margin-top: 0px;
        color: #cec950;
        border: none;
        cursor: pointer;
        transition: .2s;
        width: 100%;
      }
      .button:hover {
        letter-spacing: 2px;
      }
      .error::placeholder {
        color: #ff4d4d;
      }
      .form-message {
        margin-top: 5px;
        padding: 10px;
        opacity: 0;
        transition: 0.2s ease;
        color: black;
        border-radius: 4px;
        box-shadow: 0 0 2px rgba(51, 51, 51, 0.3);
      }
      .pageItem {
        display: flexbox;
        position: relative;
        width: 90vw;
        height: 70vh;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        overflow-x: hidden;
      }
      .pageItem2 {
        display: flexbox;
        position: relative;
        width: 90vw;
        height: 65vh;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        overflow-x: hidden;
      }
}