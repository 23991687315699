.home {
  
    .pageTitre {
        position: absolute;
        display: flex;
        margin-top: 10vh;
        margin-left: 5rem;
        color: white;
        font-size: 1.5rem;
    }

    .pageTitre2 {
        position: absolute;
        display: flex;
        margin-top: 10vh;
//        margin-left: 1rem;
        color: white;
        font-size: 1.4rem;
    }

    .page {
        display: flex;
        position: absolute;
        width: 95%;
        margin-top: 28vh;
      }

      .pageMentions {
        display: flexbox;
        position: relative;
        width: 60vw;
        height: 60vh;
        margin-left: 10vw;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        overflow-y:auto;
      }

     .pageCGU {
        display: flexbox;
        position: relative;
        width: 60vw;
        height: 60vh;
        margin-left: 10vw;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        overflow-y:auto;
        border: 3px solid #0527a1;
      }

      .pageCGU2 {
        display: flexbox;
        position: relative;
        width: 80vw;
        height: 50vh;
        margin-left: 5vw;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        overflow-y:auto;
        border: 3px solid #0527a1;
      }

      .pageItem {
        display: flexbox;
        position: relative;
        width: 90vw;
        height: 62vh;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        overflow-x: hidden;
        overflow-y:auto;
      }

      .pageItem2 {
        display: flexbox;
        position: relative;
        width: 90vw;
        height: 55vh;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        overflow-x: hidden;
        overflow-y:auto;
      }

    .pageTexte {
        display: flex;
        position: relative;
        margin-top: 6vh;
        margin-left: 2vw;
    }

    .pageTexte2 {
        display: flex;
        position: relative;
    }

    .textTitre {
        margin-left: 2vw;
        font-size: 1.2rem;
}

    .retraitTexte {
        margin-left: 4vw;
        font-size: 0.8rem;
        margin-right: 10px;
    }

    .retraitTexte2 {
        margin-left: 4vw;
        font-size: 0.8rem;
        margin-right: 10px;
    }

    /* Dimensions Fond d'Ecran */
    .imageMain {
        position: absolute;
        margin-top: 5vh;
        width: 85vw;
        height: 65vh;
        margin-bottom: 5vw;
        align-items: center;
        justify-content: center;
        opacity: 65%;
    }

    /* Dimensions image d'une activité */
    .imageItem {
        width: 12vw;
        height: 12vw;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .imageItem2 {
        width: 20vw;
        height: 20vw;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    /* Dimensions du texte d'une activité */
    .textItem {
        margin-top: 1vw;
        width: 12vw;
        background-color: rgb(144,144,144);
        color: white;
        text-align: center;
        font-size: 0.8rem;
    }

    .textItem2 {
        margin-top: 1vw;
        width: 20vw;
        background-color: rgb(144,144,144);
        color: white;
        text-align: center;
        font-size: 0.8rem;
    }

    /* Container */
    .container {
        margin-left: 2vw;
        width: 55vw;
        background-color: white;
        border: 1px solid rgba(90, 90, 92, 0.932);
    }
    
    .container2 {
        margin-left: 2vw;
        width: 75vw;
        background-color: white;
        border: 1px solid rgba(90, 90, 92, 0.932);
    }
    
    /* Dimensions d'une image développement */
    .imageBlock {
        margin-left: 2vw;
        width: 8vw;
        height: auto;
    }

    .imageDev {
        margin-left: 2vw;
        width: 8vw;
        height: auto;
    }

    .texteInput {
        width: 7vw;
        height: 2vh;
        color: rgb(63, 81, 148);
        font-size: 1rem;
    }

    .formInput {
        width: 20vw;
        height: 3vh;
        border-radius: 10px;
        color: rgb(63, 81, 148);
        font-size: 1rem;
        color: rgb(63, 81, 148);   
    }

    .formSubmit {
        width: 5vw;
        height: 3vh;
        border-radius: 10px;
        color: rgb(63, 81, 148);
        font-size: 1rem;
        color: rgb(63, 81, 148);   
    }

    .textareaInput {
        width: 30vw;
        height: 30vh;
        border-radius: 10px;
        color: rgb(63, 81, 148);
        font-size: 1rem;
        color: rgb(63, 81, 148);
    }

    .bouton {
        background-color: blue;
        color: white;
    }

    .pageClient {
        display: flexbox;
        position: relative;
        background-color: white;
        width: 65vw;
        height: 60vh;
        margin-left: 5vw;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        overflow-y:auto;
        border: 3px solid #0527a1;
    }

    .modelClient {
        margin-top: 2vh;
        margin-left: 5vw;
        padding-top: 1vh;
        padding-left: 2vw;
        width: 50vw;
        height: 15vh;
        background-color:rgb(120, 120, 121);
        color: white;
        border: 3px solid black;
        cursor: pointer;

    }

    .modelMessage {
        margin-top: 2vh;
        margin-left: 5vw;
        padding-top: 1vh;
        padding-left: 2vw;
        width: 50vw;
        height: 15vh;
        background-color:rgb(120, 120, 121);
        color: white;
        border: 3px solid black;
        cursor: pointer;
    }

    .modelMessageNoPointer {
        margin-top: 2vh;
        margin-left: 5vw;
        padding-top: 1vh;
        padding-left: 2vw;
        width: 50vw;
        height: 15vh;
        background-color:rgb(120, 120, 121);
        color: white;
        border: 3px solid black;
    }

    .modelClient:hover {
        color: rgb(22, 25, 240);
        background-color: rgb(211, 207, 207);
    }

    .modelMessage:hover {
        color: rgb(22, 25, 240);
        background-color: rgb(211, 207, 207);
    }

    .modelTitre {
        width: 30vw;
    }
    .modelTitreMessages {
        margin-left: 5vw;
        padding-left: 4px;
        width: 30vw;
    }
    .modelEspaceMin {
        width: 15vw;
    }
    .modelEspace {
        width: 15vw;
    }

    .modelData {
        width: 40vw;
    }

    .modelIcone {
        margin-top: -10vh;
        margin-right: 25px;
        width: 30px;
        height: 30x;
    }

    .modelIconeAdd {
        margin-top: -1vh;
        margin-left: 5vw;
        width: 35px;
        height: 35x;
    }

    .modelEmail {
        margin-left: 2vw;
        width: 30vw;
    }

    .sizeLabel {
        width: 3vw;
    }

    .sizeMessage {
        margin-left: 5vw;
        width: 3vw;
    }

    .button {
        position: relative;
        margin-top: 3vh;
//        margin-left: 5vw;
        width: 6vw;
        background-color: rgb(9, 9, 207);
        color: white;
        border-radius: 10px;
    }

}


